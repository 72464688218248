import React from 'react';
import homecare from "../assets/homecare.png";
import crud from "../assets/crud.png";
import ecommerce from "../assets/ecommerce.png";
import hnur from "../assets/hnur.jpg";
import {Link} from "react-router-dom";

const Explore = () => {
  return (
    <div className='w-full px-4 bg-white'>
       <div className='max-w-[1240px] mx-auto text-center flex-block'>
       <h1 className='md:text-4xl text-2xl pt-4'>Introduction to My Portfolio</h1>
       <img className="h-20 mx-auto object-cover rounded-full w-20 m-2" src={hnur} alt='hnur'/>
       <p className='font-bold'>Hassan M</p>
       <p className='text-gray-500 text-[12px]'>Full Stack Developer </p>
       <p className='text-gray-500 text-[12px]'>United States <Link to="https://www.linkedin.com/in/hassan-m-6ba3a6164/">Contact Info</Link> </p>
       <p className='text-gray-500 text-[12px]'>46 followers </p>
       </div>
        <div className='max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8'>
        <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
            <h2 className='text-2xl text-center font-bold py-8'>Homecare</h2>
            <div className='text-center font-medium '>
              <Link to="https://homecare.hnurm.com/"><img src={homecare} alt="Homecare"/></Link>
            </div>
            </div>

            <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
            <h2 className='text-2xl text-center font-bold py-8'>Ecommerce</h2>
            <div className='text-center font-medium '>
              <Link to="https://ecommerce.hnurm.com/"><img src={ecommerce} alt="Homecare"/></Link>
            </div>
            </div>

            <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
            <h2 className='text-2xl text-center font-bold py-8'>CRUD Operation</h2>
            <div className='text-center font-medium '>
              <Link to="https://crud.hnurm.com/"><img src={crud} alt="Homecare"/></Link>
            </div>
            </div>
      </div>
    </div>
  )
}

export default Explore