import React from 'react';
import { Link } from 'react-router-dom';
import tech from "../assets/tech.jpg";

const Bussinesspro = () => {
  return (
    <div className='text-white max-w-[1240px] mx-auto'>
    <div className='grid md:grid-cols-2 gap-8 bg-white text-[#020a16]'>
      <div className='w-full mx-auto m-2 p-2'>
        <img src={tech} alt='tech' className=''/>
      </div>
      <div className='w-full m-2 p-2'>
        <h1 className='md:text-2xl text-center mb-2 font-bold'>Business Package</h1>
        <h1 className='text-center md:text-2xl'>$ 6,500</h1>
      <p>
      The Bussiness Web Design package offers, 40 pages that is perfect 
      for highlighting your service offerings and overall brand vision. 
      We will work together to create an elevated design that aligns with your 
      brand and highlights the assets that makes you shine.
      </p>
      <Link to='/contact'>
          <button className='bg-[#162875] w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3 flex flex-col items-center justify-center text-white hover:bg-[#3d59d5]'>Contact Us</button>
          </Link>
      </div>
    </div>

  </div>
  )
}

export default Bussinesspro