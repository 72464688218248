import React from 'react';
import Laptop from "../assets/laptop.jpg";

const Design = () => {
  return (
    <div className='w-full bg-white py-16 px-4'>
        <div className='max-w-[1240px] mx-auto grid md:grid-cols-2 '>
            <img className='w-[500px] mx-auto my-4' src={Laptop} alt='Laptop' />
            <div className='flex flex-col justify-center'>
              <p className='text-[#00df9a] uppercase font-bold'>A Design You Love</p>
              <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>FUNDAMENTALS OF WEB DESIGN</h1>
              <p>
              Website design creates the overall appearance and reflection 
              of the company's brand and information. A whole hnur of techniques 
              and skills are involved in creating a web page design to give it the desired look. 
              So web design is about deciding on many elements, such as the layout, 
              graphics, colors, fonts, structure, content, text styles, 
              interactive features, imagery, interface, standardized code, etc. 
              You also need to create a logo to enhance the look of your site and attract users. 
              Professional website design maintains an appropriate balance 
              in the different elements and ensures consistency and integrity of the design.
              </p>
              <button className='text-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto md:mx-0 py-3 bg-black'>Get Started</button>

            </div>
        </div>

    </div>
  )
}

export default Design