import React, { useState } from 'react';
import {AiOutlineMenu,AiOutlineClose} from "react-icons/ai";
import { Link } from 'react-router-dom';
const Navbar = () => {
  const [nav,setNav] = useState(false);
  const handleNav = () => {
    setNav(!nav)
  }
  return (
    <div className='flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4 text-white'>
      <Link to="/"><h1 className='w-full text-3xl font-bold text-[#00df9a]'>HNUR</h1></Link>
      <ul className='md:flex hidden'>
        <Link to="/" ><li className='p-4'>Home</li></Link>
        <Link to="/explore"><li className='p-4'>Explore</li></Link>
        <Link to='/services'><li className='p-4'>Services</li></Link>
        <Link to='/card'><li className='p-4'>Pricing</li></Link>
        <Link to="/contact"><li className='p-4'>Contact</li></Link>
      </ul>
      <div onClick={handleNav} className='block md:hidden'>
        {
          nav ? <AiOutlineClose size={20}/> : <AiOutlineMenu size={20}/>
        }
      </div>
      <div className={nav ? 'fixed left-0 top-0 w-[60%] h-full border-r border-gray-900 bg-[#000300] ease-in-out duration-500 md:hidden' : 'fixed left-[-100%] ease-in-out duration-500'}>
     <Link to="/"> <h1 className='w-full text-3xl font-bold text-[#00df9a] m-4'>HNURM</h1></Link>
      <ul className='uppercase'>
       <Link to="/"> <li className='p-4 border-b border-gray-600'>Home</li></Link>
       <Link to="/explore"> <li className='p-4 border-b border-gray-600'>Explore</li></Link>
        <Link to='/services'><li className='p-4 border-b border-gray-600'>Services</li></Link>
        <Link to='/card'><li className='p-4 border-b border-gray-600'>Pricing</li></Link>
       <Link to="/contact"> <li className='p-4'>Contact</li></Link>
      </ul>
        
      </div>

    </div>
  )
}

export default Navbar