import React from 'react';
import { ReactTyped } from "react-typed"
import {Link} from "react-router-dom";

const Hero = () => {
  return (
    <div className='max-w-[800px] w-full h-full mx-auto text-center flex flex-col justify-center py-4'>
        <p className='text-[#00df9a] font-bold p-2'>Web Design & Development </p>
        <h1 className='md:text-4xl sm:text-3xl text-2xl text-white'>Website Design & Developments Solutions</h1>
        <div className='flex justify-center items-center'>
            <p className='text-white mt-3 md:text-3xl sm:text-2xl text-1xl text-center pl-2 py-4'>Web Designs <ReactTyped strings={['Start Up $ 650','Professional $ 999','Business Pro $ 1,498']} typeSpeed={120} backSpeed={130} loop/>
            </p>
        </div>
        <p className='md:2xl text-xl font-bold text-gray-600 p-2'>Monitor your data analytics to increase revenue for Business</p>
        <Link to="/explore">
        <button className='bg-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto py-3'>Our Work</button>
        </Link>
       
    </div>
  )
}

export default Hero