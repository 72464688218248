import React from "react";
import {createBrowserRouter,
  createRoutesFromElements,
  Route,RouterProvider} from "react-router-dom";
import Home from "./cpmponents/Home";
import Services from "./cpmponents/Services";
import Pricing from "./cpmponents/Pricing";
import Routelayout from "./cpmponents/Routelayout";
import Contact from "./cpmponents/Contact";
import Submit from "./cpmponents/Submit";
import Explore from "./cpmponents/Explore";
import Cards from "./cpmponents/Cards";
import Startup from "./cpmponents/Startup";
import Professional from "./cpmponents/Professional";
import Bussinesspro from "./cpmponents/Bussinesspro";



function App() {
  const router = createBrowserRouter(createRoutesFromElements(
    <Route path="/" element={<Routelayout/>}>
      <Route index element={<Home/>}/>
      <Route path="/services" element={<Services/>}/>
      <Route path="/pricing" element={<Pricing/>}/>
      <Route path="/contact" element={<Contact/>}/>
      <Route path="/submit" element={<Submit/>}/>
      <Route path="/explore" element={<Explore />}/>
      <Route path="/card" element={<Cards/>}/>
      <Route path="/startup" element={<Startup/>}/>
      <Route path="/professional" element={<Professional/>}/>
      <Route path="/bussiness" element={<Bussinesspro/>}/>
      <Route path="/services" element={<Services/>}/>
    </Route>
  ))
  

  return (
    <div>
      <RouterProvider router={router}/>
    </div>
  );
}

export default App;
