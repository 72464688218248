import React from 'react';
import { PiBuilding,PiBuildingApartment,PiBuildingApartmentBold } from "react-icons/pi";
import { Link } from 'react-router-dom';
const Cards = () => {
  return (
   
     <div className='w-full bg-white'>
        <div className='max-w-[1240px mx-auto]'>
            <h1 className='text-center md:text-4xl text-xl pt-4 '>Professional Web Design Services</h1>

        </div>
        <div className='py-[10rem] px-4'>
        <div className='max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8'>
            <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
            <PiBuilding size={80} className='mx-auto mt-[-3rem] bg-white '/>
            <h2 className='text-2xl text-center font-bold py-8'>Basic Package</h2>
            <p className='text-center text-4xl font-bold'>$ 3,500</p>
            <div className='text-center font-medium '>
                <p className='py-2 border-b mx-8 mt-8'>Up to 20 Pages</p>
                <p className='py-2 border-b mx-8'>Logo Design</p>
                <p className='py-2 border-b mx-8'>Responsive Design</p>
                <p className='py-2 border-b mx-8'>Search Optimized</p>
                <p className='py-2 border-b mx-8'>Image Optimization </p>
            </div>
            <Link to='/startup'>
            <button className='bg-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3 flex flex-col items-center justify-center'>Basic Package</button>
            </Link>
            </div>

            <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300 bg-gray-100'>
            <PiBuildingApartment size={80} className='mx-auto mt-[-3rem] bg-white '/>
            <h2 className='text-2xl text-center font-bold py-8'>Professional Package</h2>
            <p className='text-center text-4xl font-bold'>$ 5,000</p>
            <div className='text-center font-medium '>

            <p className='py-2 border-b mx-8 mt-8'>Up to 30 Pages</p>
                <p className='py-2 border-b mx-8'>Logo Design</p>
                <p className='py-2 border-b mx-8'>Responsive Design</p>
                <p className='py-2 border-b mx-8'>Search Optimized</p>
                <p className='py-2 border-b mx-8'>Image Optimization </p>
            </div>
            <Link to='/professional'>
            <button className='bg-black text-white w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3 flex flex-col items-center justify-center'>Professional Package</button>
            </Link>
            </div>

            <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
            <PiBuildingApartmentBold size={80} className='mx-auto mt-[-3rem] bg-white '/>
            <h2 className='text-2xl text-center font-bold py-8'>Business Package</h2>
            <p className='text-center text-4xl font-bold'>$ 6,500</p>
            <div className='text-center font-medium '>
                <p className='py-2 border-b mx-8 mt-8'>Up to 40 Pages</p>
            <p className='py-2 border-b mx-8'>Logo Design</p>
                <p className='py-2 border-b mx-8'>Responsive Design</p>
                <p className='py-2 border-b mx-8'>Search Optimized</p>
                <p className='py-2 border-b mx-8'>Image Optimization </p>
              
            </div>
           <Link to='/bussiness'>
           <button className='bg-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3 flex flex-col items-center justify-center'>Business Package</button>
           </Link>
            </div>



        </div>

    </div>
   
     </div>
  )
}

export default Cards