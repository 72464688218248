import React from 'react'
import Hero from './Hero'
import Design from './Design'
import Newsletter from './Newsletter'
import Cards from './Cards';

const Home = () => {
 
  return (
    <div>
     <Hero/>
      <Design/>
      <Newsletter/>
      <Cards/>
    </div>
  )
}

export default Home