import React, { useState } from "react";
import { FaUser } from "react-icons/fa";
import { IoCallOutline } from "react-icons/io5";
import axios from "axios";
import {useNavigate} from "react-router-dom";

const Contact = () => {
  const [values,setValues] = useState({fname:'',email:''});
  const navigate = useNavigate()
 
    const handleSubmit = (e) => {
      e.preventDefault();
      axios.post("/api/add_contact", values)
      .then((res) => {
        navigate('/submit')
        console.log(res)
      })
      .catch((err) => console.log(err))};
  
 

  
  return (
    <div className="max-w-[1240px] w-full justify-center items-center text-center mx-auto">
        <h1 className="lg:text-4xl md:text-3xl sm:text-2xl text-white">Get in touch</h1>
        <p className="text-gray-500 p-4 text-xl">Want to get in touch? We'd love to hear from you. Here's how you can reach us ...
        <IoCallOutline className="text-4xl text-white mx-auto"/>703-626-0565
        </p>
       
        <div className="flex bg-[#000300] p-2">
        <div className="max-w-[484px] w-full mx-auto p-6 shadow-lg bg-white rounded-md ">
        <h1 className="flex justify-center mt-3 text-3xl text-center font-semibold"><FaUser className="mr-2"/>Contact</h1>
      <hr className="mt-3 mb-8"/>
      <form onSubmit={handleSubmit}>
      <div className="text-left">
        <label className="font-semibold text-xl">Full Name</label>
        <input type="text" name="fname" className="block text-base mb-2 border w-full px-2 py-1 
        focus:outline-none focus:ring-0 focus:border-gray-600" placeholder="Enter Full Name" required
        onChange={(e)=>setValues({...values,fname:e.target.value})}/>
      </div>

      <div className="text-left">
        <label className="font-semibold text-xl">Email</label>
        <input type="email" name="email" className="block text-base mb-2 border w-full px-2 py-1 
        focus:outline-none focus:ring-0 focus:border-gray-600" placeholder="Enter Eamil" required  
        onChange={(e)=>setValues({...values,email:e.target.value})}/>
      </div>
      <div className="mt-5">
        <button type="submit" className="border-2 border-[#000300] bg-[#000300] text-white py-1 w-full 
        rounded-md hover:bg-transparent hover:text-[#000300] font-semibold">Submit</button>
      </div>
      </form>
      </div>
    </div>
       
    </div>
  )
}

export default Contact