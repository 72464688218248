import React from 'react';
import Startup from './Startup';
import Professional from './Professional';
import Bussinesspro from './Bussinesspro';

const Services = () => {
  return (
    <div>
      <Startup/>
      <Professional/>
      <Bussinesspro/>

    </div>
  )
}

export default Services