import React from 'react';
import { IoCallOutline } from "react-icons/io5";

const Submit = () => {
  return (
    <div className='max-w-[1240px] mx-auto justify-center items-center text-center text-white mt-6'>
      <h1 className='md:text-4xl text-2xl'>Thank You For Contacting Us</h1>
      <p className='md:text-2xl sm:text-xl m-2 p-2'>
      We received your message and our team has already started working on it. If the inquiry is urgent, 
      it’s best to use the number listed below to talk to our team. Otherwise, 
      we’ll reply by email asap. Talk to you soon, 
      and thanks again for being awesome! <span className='text-bold text-gray-700'>HNUR</span>
      </p>
      <p className="text-gray-500 p-4 text-xl">
        <IoCallOutline className="text-3xl text-white mx-auto"/>703-626-0565
        </p>
     
      
      
    </div>
  )
}

export default Submit