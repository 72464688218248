import axios from "axios";
import React, { useState } from "react";


const Newsletter = () => {
    const [values,setValues] = useState({email:''});
    const [message,setMessage] = useState('');
    
    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post("/api/add_newsletter", values)
        .then(res => {
            if(res.data.Status === "Thank you for subscribing to our newsletter"){
                setMessage(res.data.Status);
                // Reload page 5 seconds after form submission
            setTimeout(function () {
            window.location = "/"; 
        }, 5000);
               
            }
        })
        .then(err => console.log(err))
       }
     
  return (
    <div className='w-full py-16 text-white px-4'>
        <div className='max-w-[1240px] mx-auto grid lg:grid-cols-3'>
           <form onSubmit={handleSubmit}>
           <div className='lg:col-span-2 my-4'>
                <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>Want tips & tricks to optimize your flow?</h1>
                <p>Sign up to our newsletter and stay up to date</p>
            </div>
            <div className='my-4'>
                <div className='flex flex-col sm:flex-row items-center justify-between w-full'>
                    <input className='p-3 flex w-full rounded:md text-black' type='email' placeholder='Enter Email'  name="email" required 
                     onChange={(e)=>setValues({...values,email:e.target.value})} />
                    <button className='bg-[#00df9a] text-black w-[200px] rounded-md ml-4 font-medium my-6 px-6 py-3' 
                     >Notify Me</button>
                </div>
                <p className="text-center sm:text-xl md:text-2xl">{message}</p>
                <p> We care about protection of your email. Read our <span className='text-[#00df9a]'>Privacy Policy</span>.</p>
            </div>
           </form>

        </div>

    </div>
  )
}

export default Newsletter